import { useRouteError } from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { Card } from "~/components/card";
import HeatPumpSadFace from "~/images/heat-pump-with-sad-face.svg?url";

export const ErrorScreen = () => {
	const error = useRouteError() as { status?: number };

	if (error.status !== 404 && error.status !== 403) {
		// don't log 404 and 403 errors
		captureRemixErrorBoundaryError(error);
	}
	const { t } = useTranslation();

	const { title, message, button } = useErrorTranslation(error.status);

	return (
		<div className="h-full-ios-screen flex p-4">
			<Card className="mx-auto mb-auto w-full max-w-[500px] flex-col items-center gap-1 p-6">
				<img
					className="my-6"
					width={204 * 1.2}
					height={131 * 1.2}
					src={HeatPumpSadFace}
					alt={t("heatPumpWithSadFace")}
				/>
				<h1 className="m-0">{title}</h1>
				<span className="mx-2 text-center sm:mx-8">{message}</span>
				{/*styles where made inline because MUI styles are not loaded on error boundaries*/}
				<a
					href="/"
					className="rounded-sm mt-8 bg-brand px-4 py-2 text-black no-underline hover:bg-gray2"
				>
					{button}
				</a>
			</Card>
		</div>
	);
};

function useErrorTranslation(
	errorStatus: number | undefined,
): Record<"title" | "message" | "button", string> {
	// translations cannot be always shown as described here
	// https://github.com/sergiodxa/remix-i18next/discussions/222

	const { t } = useTranslation();
	const titleTranslation = t("pageNotFound");
	const isI18NWorking = titleTranslation !== "pageNotFound";
	if (isI18NWorking) {
		if (errorStatus === 404) {
			return {
				title: t("pageNotFound"),
				message: t("pageDoesntExist"),
				button: t("backToHome"),
			};
		} else if (errorStatus === 403) {
			return {
				title: t("accessDenied"),
				message: t("youHaveNoAccess"),
				button: t("backToHome"),
			};
		} else {
			return {
				title: t("ooops"),
				message: t("somethingWentWrong"),
				button: t("backToHome"),
			};
		}
	} else {
		return {
			title: "Ooops!",
			message: "Da ist etwas schief gelaufen.",
			button: "Zurück zur Startseite",
		};
	}
}
